@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");
body {
  height: 100%;
  background: url(assets/WallpaperDog-20468644.jpeg) no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "Alex Brush";
  background-color: rgba(160, 160, 160, 0.5);
}

.navFont {
  font-family: "Dancing Script";
}
